
import { defineComponent } from "vue";
import { MdInputField, MdInputFieldWrapper } from "@/components/md/MdInput";
import { MdButton } from "@/components/md/MdButton";

export default defineComponent({
  name: "PasswordRecovery",
  components: {
    MdInputFieldWrapper,
    MdInputField,
    MdButton,
  },
  data() {
    return {};
  },
  methods: {
    login() {
      console.log("login");
    },
  },
});
